/*src/pages/GuideApp.js*/
import React from 'react';
import { Typography, List, Card } from 'antd';
import usePageTitle from '../usePageTitle';

const { Title, Paragraph } = Typography;

function GuideApp() {
    usePageTitle("가이드");
    const featureData = [
        '첫 번째 소스: 실시간으로 업데이트되는 서비스 지원',
        '재능: 다른 사용자들과 실시간으로 소통할 수 있는 채팅 기능 제공',
        '성장: 애플리케이션의 데이터, 트렌드, 성능 조정을 자유롭게 조정할 수 있음'
    ];

    const methodData = [
        '애플리케이션에서 제공하는 카테고리별로 콘텐츠를 탐색하세요.',
        '실시간 채팅으로 다른 사용자와 소통하세요.',
        '설정 메뉴에서 애플리케이션의 데이터 및 성능을 조정하세요.'
    ];

    // 인라인 스타일 객체
    const cardStyle = {
        fontFamily: "var(--font-family)",
        padding: "80px 20px",
        borderRadius: '4px',
        boxShadow: 'none' // 박스 그림자 비활성화
    };

    const listStyle = {
        fontFamily: "var(--font-family)",
        marginBottom: '20px'
    };

    return (
        <div>
            <Card bordered={false} style={cardStyle}>
                <Title level={4}>애플리케이션 기능 및 사용 가이드</Title>
                <Paragraph style={{ fontFamily: "var(--font-family)" }}>
                    이 가이드는 애플리케이션의 주요 기능들을 소개하고 각 기능의 사용 방법을 안내합니다. 
                    사용자가 애플리케이션을 더 효과적으로 사용할 수 있도록 도와드립니다.
                </Paragraph>
                <div>
                    {/* 기능 소개 */}
                    <Title level={5}>기능 소개:</Title>
                    <List
                        bordered
                        dataSource={featureData}
                        renderItem={item => <List.Item style={listStyle}>{item}</List.Item>}
                    />
                </div>
                <div>
                    {/* 사용 방법 */}
                    <Title level={5}>사용 방법:</Title>
                    <List
                        bordered
                        dataSource={methodData}
                        renderItem={item => <List.Item style={listStyle}>{item}</List.Item>}
                    />
                </div>
            </Card>
        </div>
    );
}

export default GuideApp;

