/*src/pages/SettingsApp.js */
import React, { useState, useEffect } from "react";
import { Radio, Typography, Switch, Button, Card } from "antd";
import { useDarkMode } from '../components/DarkModeContext';
import { useTheme } from '../components/ThemeContext';
import { useFont } from '../components/FontContext';
import usePageTitle from '../usePageTitle';
import './DarkMode.css';

const { Title } = Typography;

const SettingsApp = () => {
    usePageTitle("설정");
    const { isDarkMode, setIsDarkMode } = useDarkMode();
    const { themeColor, setThemeColor } = useTheme();
    const { font, setFont } = useFont();

    const cardStyle = {
        marginBottom: "20px",
    };

    const dividerStyle = {
        border: "none",
        height: "2px",
        backgroundColor: "#888",
        margin: "20px 0 20px 0"
    };

    const [isBold, setIsBold] = useState(() => {
        const savedIsBold = localStorage.getItem("isBold");
        return savedIsBold === "true";
    });

    const getButtonClassName = () => {
        return isBold ? "bold-text" : "";
    };

    const SettingDesignApp = () => {

        const handleThemeChange = (color) => {
            setThemeColor(color);
        };

        useEffect(() => {
            document.documentElement.style.setProperty('--font-family', font);
            document.documentElement.style.setProperty('--theme-color', themeColor);
            document.documentElement.style.setProperty('--font-weight', isBold ? 'bold' : 'normal');
            document.documentElement.style.setProperty('--theme-color', themeColor);
        }, [themeColor, isBold, font]);

        const handleFontChange = (e) => {
            const newFont = e.target.value;
            setFont(newFont); // 폰트 변경
            localStorage.setItem("font", newFont);
        };

        const handleBoldToggle = (checked) => {
            setIsBold(checked);
            localStorage.setItem("isBold", checked.toString());
        };

        const handleDarkModeChange = (checked) => {
            setIsDarkMode(checked);
            localStorage.setItem("darkMode", checked.toString());

            const bodyClass = document.body.classList;
            checked ? bodyClass.add('dark-mode') : bodyClass.remove('dark-mode');
        };

        useEffect(() => {
            const darkModeSetting = localStorage.getItem("darkMode") === "true";
            setIsDarkMode(darkModeSetting);
            const bodyClass = document.body.classList;
            darkModeSetting ? bodyClass.add('dark-mode') : bodyClass.remove('dark-mode');
        }, []);

        return (
            <div>
                <Title level={2}>디자인 설정</Title>
                <div>
                    <Title level={4} style={{ fontSize: "inherit" }}>라이트/다크 모드</Title>
                    <Switch checked={isDarkMode} onChange={handleDarkModeChange} />
                </div>
                <div>
                    <Title level={4} style={{ fontSize: "inherit" }}>테마 색</Title>
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#f5222d', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#f5222d')}>Dust Red</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#fa541c', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#fa541c')}>Volcano</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#fa8c16', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#fa8c16')}>Sunset Orange</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#faad14', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#faad14')}>Calendula Gold</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#fadb14', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#fadb14')}>Sunrise Yellow</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#a0d911', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#a0d911')}>Lime</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#52c41a', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#52c41a')}>Polar Green</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#13c2c2', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#13c2c2')}>Cyan</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#1677ff', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#1677ff')}>Daybreak Blue</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#2f54eb', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#2f54eb')}>Geek Blue</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#722ed1', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#722ed1')}>Golden Purple</Button>
                        <Button type="primary" className={getButtonClassName()} style={{ backgroundColor: '#eb2f96', fontFamily: "var(--font-family)" }} onClick={() => handleThemeChange('#eb2f96')}>Magenta</Button>
                    </div>
                </div>
                <div>
                    <div>
                        <Title level={5} style={{ fontSize: "inherit" }}>글자 굵게</Title>
                        <Switch checked={isBold} onChange={handleBoldToggle} />
                    </div>
                    <div>
                        <Title level={5} style={{ fontSize: "inherit" }}>폰트 설정</Title>
                        <Radio.Group onChange={handleFontChange} value={font}>
                            <Radio value="'Noto Sans KR', sans-serif">Noto Sans</Radio>
                            <Radio value="'Nanum Gothic', sans-serif">나눔고딕</Radio>
                            <Radio value="'IBM Plex', sans-serif">IBM Plex</Radio>
                            <Radio value="'Gowun Dodum', sans-serif">Gowun Dodum</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        );
    };

    const SettingValueApp = () => {
        const exportSettings = () => {
            const allSettings = {};
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                const value = localStorage.getItem(key);
                allSettings[key] = value;
            }
            const blob = new Blob([JSON.stringify(allSettings)], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'settings.json';
            a.click();
        };

        const importSettings = () => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'application/json';
            input.onchange = (e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => {
                    const obj = JSON.parse(reader.result);
                    for (const [key, value] of Object.entries(obj)) {
                        localStorage.setItem(key, value);
                    }
                    window.location.reload();
                };
            };
            input.click();
        };

        const resetSettings = () => {
            localStorage.clear();
            window.location.reload();
        };

        return (
            <div>
                <Title level={2}>설정값 관리</Title>
                <div>
                    <Button
                        type="primary"
                        className={getButtonClassName()}
                        style={{ marginRight: "10px", fontFamily: "var(--font-family)" }}
                        onClick={exportSettings}
                    >
                        설정값 내보내기
                    </Button>
                    <Button
                        type="primary"
                        className={getButtonClassName()}
                        style={{ marginRight: "10px", fontFamily: "var(--font-family)" }}
                        onClick={importSettings}>
                        설정값 가져오기
                    </Button>
                    <Button
                        type="primary" danger
                        className={getButtonClassName()}
                        style={{ fontFamily: "var(--font-family)" }}
                        onClick={resetSettings}>
                        설정 초기화
                    </Button>
                </div>
            </div>
        );
    };

    const SettingQuestionApp = () => {
        const handleContactClick = () => {
            window.location.href = "https://www.naver.com/";
        };
        return (
            <div>
                <div>
                    <Title level={4}>문의하기</Title>
                    <Button type="primary" className={getButtonClassName()} style={{ fontFamily: "var(--font-family)" }} onClick={handleContactClick}>
                        문의하기
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className={isDarkMode ? 'dark-mode' : ''} style={{ padding: "100px 20px" }}>
            <Card style={cardStyle}>
                <SettingDesignApp />
            </Card>
            <hr style={dividerStyle} />
            <Card style={cardStyle}>
                <SettingValueApp />
            </Card>
            <hr style={dividerStyle} />
            <Card style={cardStyle}>
                <SettingQuestionApp />
            </Card>
        </div>
    );
};

export default SettingsApp;

