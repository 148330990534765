// ChatApp.js
import React, { useState, useEffect, useRef} from 'react';
import { Avatar, Layout, Input, Button, List, Card, Modal, Spin } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useDarkMode, DarkModeProvider } from '../components/DarkModeContext'; 
import usePageTitle from '../usePageTitle';
import './styles.css';

const { Content } = Layout;


function ChatApp() {
    usePageTitle("기능");
    const { isDarkMode } = useDarkMode();

    const [nickname, setNickname] = useState('');
    const [chats, setChats] = useState('');
    const [messages, setMessages] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [lastChatId, setLastChatId] = useState(1);
    const messageListRef = useRef(null);
    const [pendingMessage, setPendingMessage] = useState(null);
    const fetchChatsCalled = useRef(false);
    

    const fetchChats = async (lastChatId) => {
        try {
            const response = await axios.post('https://api.alice.uiharu.dev/uncomment/api.php', {
                LastChat: lastChatId,
            });
            if (response.data.StatusCode === 200) {
                // 새 메시지가 중복되지 않도록 기존 메시지의 ID와 비교합니다.
                const newMessages = response.data.data.filter(newMessage => 
                    !messages.some(message => message.id === newMessage.id));
                
                // 중복되지 않은 메시지만 상태에 추가합니다.
                setMessages(prevMessages => [...newMessages, ...prevMessages]);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (fetchChatsCalled.current) {
          return;
        }
        fetchChats(lastChatId);
        fetchChatsCalled.current = true;
      }, [lastChatId]);

    const scrollChatToBottom = () => {
        const messageListElement = messageListRef.current;
        if (messageListElement) {
            messageListElement.scrollTop = messageListElement.scrollHeight - messageListElement.clientHeight;
        }
    };

    useEffect(() => {
        scrollChatToBottom();
    }, [messages]); 

    useEffect(() => {
        const messageListElement = messageListRef.current;
        if (messageListElement) {
            messageListElement.scrollTop = messageListElement.scrollHeight - messageListElement.clientHeight;
        }
    }, [messages]);

    
        const modalContentClass = isDarkMode ? 'modal-content-dark' : 'modal-content-normal';  

        const handleScroll = () => {
            const messageListElement = messageListRef.current;
            if (messageListElement && messageListElement.scrollTop === 0) {
              // 첫 번째 메시지의 ID를 가져옵니다. 이 경우 가장 오래된 메시지입니다.
              const oldestMessageId = messages[0]?.id;
              
              // 더 이상 불러올 메시지가 없을 때 (즉, 가장 오래된 메시지의 ID가 23일 때),
              // 추가적인 메시지를 불러오는 것을 중지합니다.
              if (oldestMessageId !== 1) {
                fetchChats(lastChatId + 10); // 또는 적절한 로직으로 변경
                setLastChatId(oldLastChatId => oldLastChatId + 10); // lastChatId를 적절하게 업데이트
              }
            }
          };

    const handleSend = async () => {
        setIsSending(true);
        const newMessage = {
            nickname,
            comment: '검토 중...',
            originalComment: chats,
            id: uuidv4()
        };
        setPendingMessage(newMessage);
        setMessages(prevMessages => [...prevMessages, newMessage]);

        try {
            const response = await axios.post('https://api.alice.uiharu.dev/uncomment/api.php', {
                nickname,
                chats,
            });
            if (response.data.StatusCode === 200) {
                const maliciousScore = response.data.prediction.malicious;
                const comment = maliciousScore >= 0.7 ? '앨리스가 악성댓글을 감지했어요!' : chats;
                const updatedMessage = { ...newMessage, comment, is_malicious: maliciousScore >= 0.7 ? 'Y' : 'N' };
                setMessages(prevMessages => {
                    return prevMessages.map(msg => (msg.id === newMessage.id ? updatedMessage : msg));
                });
                setPendingMessage(null);
            }
        } catch (error) {
            console.error('Error:', error);
        }
        setIsSending(false);
        setChats('');
        scrollChatToBottom();
    };

    const handleViewChat = (chat) => {
        setSelectedChat(chat.originalComment || chat.comment);
        setVisible(true);
    };

    const handleClose = () => {
        setVisible(false);
        setSelectedChat(null);
    };

    const processInputText = (text) => {
        // 끝에 있는 '\ㅜ' 제거
        if (text.endsWith("\\ㅜ")) {
            text = text.slice(0, -2);
        }

        // '\n'을 실제 줄바꿈으로 변환
        text = text.replace(/\\n/g, "\n");

        // 끝에 있는 '\n' 제거
        if (text.endsWith("\n")) {
            text = text.slice(0, -1);
        }

        return text;
    };

    // Input.TextArea의 onChange 이벤트 핸들러
    const handleInputChange = (e) => {
        const processedText = processInputText(e.target.value);
        setChats(processedText);
    };

    const renderChatBubble = (item) => {
        const avatarUrl = `https://ui-avatars.com/api/?name=${item.nickname}&background=random&color=random`;
  

        return (
            <List.Item style={{ padding: '10px', backgroundColor: item.nickname === nickname ? '#d4f1f4' : '#f0f2f5', borderRadius: '10px', marginBottom: '10px', ...isDarkMode ? darkModeStyle : normalModeStyle }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', ...isDarkMode ? darkModeStyle : normalModeStyle}}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
                        <Avatar src={avatarUrl} />
                        <div style={{ marginTop: '5px'}}>{item.nickname}</div>
                    </div>
                    <div style={{
                        padding: '10px',
                        borderRadius: '10px',
                        backgroundColor: item.nickname === nickname ? '#e6f7ff' : '#ffffff',
                        maxWidth: '80%',
                        wordWrap: 'break-word',
                        ...isDarkMode ? darkModeStyle : normalModeStyle
                    }}>
                        {item.is_malicious === 'Y'
                            ? <div style={{ fontFamily: "var(--font-family)", ...isDarkMode ? darkModeStyle : normalModeStyle}}>
                                앨리스가 악성댓글을 감지했어요!
                                <Button className="global-bold" style={{ width: '35%', fontFamily: "var(--font-family)"}} type="primary" onClick={() => handleViewChat(item)}>채팅 보기</Button>
                            </div>
                            : item.comment}
                    </div>
                </div>
            </List.Item>
        );
    };

    // 다크 모드 스타일을 정의합니다.
    const darkModeStyle = {
        backgroundColor: '#333333', //배경색
        color: 'white' //텍스트 글자색
    };

    const normalModeStyle = {
        backgroundColor: 'white', //배경색
        color: 'black'  //텍스트 글자색
    };

    const layoutStyle = isDarkMode ? {
        backgroundColor: '#333333', // 다크 모드일 때 전체 배경색
        color: 'white', // 다크 모드일 때 전체 텍스트 색상
        minHeight: '100vh'
      } : {
        backgroundColor: 'white', // 일반 모드일 때 전체 배경색
        color: 'black', // 일반 모드일 때 전체 텍스트 색상
        minHeight: '100vh'
      };


    // 컴포넌트를 반환합니다.
    return (
        <DarkModeProvider>
            <Layout style={layoutStyle}>
                <Content style={{ padding: '70px 10px', ...isDarkMode ? darkModeStyle : normalModeStyle, marginTop: 30 }}>
                    <Card style={{ ...isDarkMode ? darkModeStyle : normalModeStyle }}>
                        <div
                            ref={messageListRef}
                            onScroll={handleScroll}
                            style={{ padding: '10px', maxHeight: '50vh', overflowY: 'auto', marginBottom: '10px' }}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={messages}
                                renderItem={renderChatBubble}
                            />
                        </div>
                    </Card>
                    <Card style={{ marginTop: 30, ...isDarkMode ? darkModeStyle : normalModeStyle }}>
                        <Input
                            placeholder="Nickname"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            style={{ marginBottom: '10px', ...isDarkMode ? darkModeStyle : normalModeStyle }}
                        />
                        <Input.TextArea
                placeholder="Type your message..."
                value={chats}
                onChange={handleInputChange} // 변경된 핸들러 사용
                style={{ marginBottom: '10px', ...isDarkMode ? darkModeStyle : normalModeStyle }}
                rows={4}
            />
                        <Button
                            style={{ fontFamily: "var(--font-family)" }}
                            className="global-bold"
                            type="primary"
                            onClick={handleSend}
                            disabled={isSending || nickname.trim() === '' || chats.trim() === ''}
                        >
                            {isSending ? <Spin /> : 'Send'}
                        </Button>
                    </Card>
                    <Modal
                        title={<span style={{ ...isDarkMode ? darkModeStyle : normalModeStyle }}>악성 채팅 내용</span>}
                        style={{ fontFamily: "var(--font-family)" }}
                        open={visible}
                        onOk={handleClose}
                        onCancel={handleClose}
                        wrapClassName={modalContentClass}
                    >
                        <div style={{ fontFamily: "var(--font-family)", ...isDarkMode ? darkModeStyle : normalModeStyle }}>
                            {selectedChat}
                        </div>
                    </Modal>
                </Content>
            </Layout>
        </DarkModeProvider>
    );
}    


export default ChatApp;