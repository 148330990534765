import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import Navbar from './components/Navbar';
import { ThemeProvider } from './components/ThemeContext';
import { DarkModeProvider } from './components/DarkModeContext';
import { FontProvider } from './components/FontContext';
import HomeApp from './pages/HomeApp'; // 홈 페이지 컴포넌트
import ChatApp from './pages/ChatApp'; // 채팅 페이지 컴포넌트
import GuideApp from './pages/GuideApp'; // 가이드 페이지 컴포넌트
import SettingsApp from './pages/SettingsApp'; // 설정 페이지 컴포넌트
import './App.css';

function App() {
  return (
    <DarkModeProvider>
      <ThemeProvider>
        <FontProvider>
        <Router>
          <Layout>
            <Navbar />
            <Routes>
              <Route path="/" element={<HomeApp />} />
              <Route path="/chat" element={<ChatApp />} />
              <Route path="/guide" element={<GuideApp />} />
              <Route path="/settings" element={<SettingsApp />} />
            </Routes>
          </Layout>
        </Router>
        </FontProvider>
      </ThemeProvider>
    </DarkModeProvider>
  );
}

export default App;