import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Drawer } from 'antd';
import { HomeOutlined, UserOutlined, DownloadOutlined, SettingOutlined, MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTheme } from './ThemeContext';
import { useDarkMode } from './DarkModeContext';
import './Navbar.css';

const { Header } = Layout;
const { SubMenu, Item, ItemGroup } = Menu;

const items = [
  {
    key: 'Home',
    icon: <HomeOutlined />,
    label: 'Home',
    link: '/'
  },
  {
    label: 'Functions',
    key: 'Profile',
    icon: <UserOutlined />,
    children: [
      {
        type: 'group',
        label: '기능',
        children: [
          {
            label: '악성댓글 탐지',
            key: 'setting:1',
            link: '/chat'
          }
        ],
      },
    ],
  },
  {
    key: 'Guide',
    icon: <DownloadOutlined />,
    label: 'Guide',
    link: '/guide'
  },
  {
    key: 'Setting',
    icon: <SettingOutlined />,
    label: 'Settings',
    link: '/settings',
  },
];

const getBrightness = (color) => {
  const rgb = parseInt(color.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  return (r * 299 + g * 587 + b * 114) / 1000;
}

const Navbar = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState('80%');
  const { isDarkMode } = useDarkMode(); // 다크 모드 상태를 가져옵니다.

  useEffect(() => {
    const updateWidth = () => {
      const screenWidth = window.innerWidth;
      const calculatedWidth = `${Math.min(screenWidth * 0.8, 736)}px`;
      setDrawerWidth(calculatedWidth);
    };

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);

    // Drawer 열릴 때 body에 no-scrollbar 클래스 추가
    if (!drawerVisible) {
      document.body.classList.add('no-scrollbar');
    } else {
      document.body.classList.remove('no-scrollbar');
    }
  };

  const { themeColor } = useTheme();
  const textColor = getBrightness(themeColor) < 128 ? 'white' : 'black';

  return (
    <Header className="header" style={{ backgroundColor: themeColor }}>
      <div className="logo" style={{ color: textColor, marginRight: '20px' }}>Alice</div>
      <Menu className="menu-main" style={{ backgroundColor: 'transparent', color: textColor, flex: 1 }} mode="horizontal" defaultSelectedKeys={['1']}>
        {items.map((item) => {
          if (item.children) {
            return (
              <SubMenu key={item.key} icon={item.icon} title={<span className="nav-text">{item.label}</span>} className="menu-item">
                {item.children.map((group) => (
  <ItemGroup
    key={group.label}
    title={
      <span style={{ color: isDarkMode ? '#fff' : undefined }}>
        {group.label}
      </span>
    }
  >
    {group.children.map((child) => (
      <Item
        key={child.key}
        style={{
          backgroundColor: isDarkMode ? '#333' : undefined,
          color: isDarkMode ? '#fff' : undefined,
          border: isDarkMode ? '1px solid #555' : undefined // 다크 모드에서 보더 추가
        }}
      >
        <Link to={child.link} style={{ color: isDarkMode ? '#fff' : undefined }}>
          {child.label}
        </Link>
      </Item>
    ))}
  </ItemGroup>
))}
              </SubMenu>
            );
          }
          return (
            <Item key={item.key} icon={item.icon} className="menu-item">
              <Link to={item.link} style={{ color: textColor }}>
                <span className="nav-text">{item.label}</span>
              </Link>
            </Item>
          );
        })}
      </Menu>
      <Button className="menu-toggle-button" type="text" onClick={toggleDrawer}>
        <MenuOutlined />
      </Button>
      <Drawer
        title="메뉴"
        placement="left"
        closable={false}
        onClose={toggleDrawer}
        visible={drawerVisible}
        width={drawerWidth}
      >
        <div className="no-scrollbar">
          <Menu mode="inline" defaultSelectedKeys={['1']}>
            {items.map((item) => {
              if (item.children) {
                return (
                  <SubMenu key={item.key} icon={item.icon} title={item.label}>
                    {item.children.map((group) => (
                      <ItemGroup key={group.label} title={group.label}>
                        {group.children.map((child) => (
                          <Item key={child.key}>
                            <Link to={child.link}>{child.label}</Link>
                          </Item>
                        ))}
                      </ItemGroup>
                    ))}
                  </SubMenu>
                );
              }
              return (
                <Item key={item.key} icon={item.icon}>
                  <Link to={item.link}>{item.label}</Link>
                </Item>
              );
            })}
          </Menu>
        </div>
      </Drawer>
    </Header>
  );
};

export default Navbar;
