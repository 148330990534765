import { useState, useEffect } from "react";
import axios from "axios";

export function useNewsData() {
    const [newsData, setNewsData] = useState([]);

    useEffect(() => {
      const fetchRSS = async () => {
        try {
          const response = await axios.get(
            "https://apis.uiharu.dev/fixcors/api.php?url=https://www.korea.kr/rss/policy.xml"
          );
          const data = new DOMParser().parseFromString(response.data, "text/xml");
          const items = data.querySelectorAll("item");
          const newsArray = Array.from(items).map(item => ({
            title: item.querySelector("title").textContent,
            link: item.querySelector("link").textContent,
            pubDate: item.querySelector("pubDate").textContent
          }));
          setNewsData(newsArray);
        } catch (error) {
          // 오류 처리
        }
      };

      fetchRSS();
    }, []);

    return newsData;
}
