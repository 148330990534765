/*src/pages/HomeApp.js*/
import React, { useState, useEffect } from 'react';
import { Card, Typography, Table } from 'antd';
import { useNewsData } from '../useNewsData';
import { useDarkMode } from '../components/DarkModeContext';
import usePageTitle from '../usePageTitle';

const { Title } = Typography;

// 뉴스 테이블 칼럼 구성
const newsColumns = [
  {
    title: "뉴스 제목",
    dataIndex: "title",
    key: "title",
    align: 'center',
    render: (text, record) => (
      <a
        href={record.link}
        target="_blank"
        rel="noopener noreferrer"
        key={record.link}
        className="news-link" // 새로운 클래스 추가
      >
        {text}
      </a>
    ),
  },
  {
    title: "날짜",
    dataIndex: "pubDate",
    key: "pubDate",
    align: 'center',
    render: (text, record) => (
      <span key={record.pubDate}>{new Date(text).toLocaleDateString()}</span>
    ),
  },
];

// NewsCard 컴포넌트 스타일
const newsCardStyle = {
  textAlign: 'center',
  padding: '20px',
  width: '100%',
  marginBottom: '20px'
};

// '최신뉴스' 제목 및 '더보기' 링크에 대한 스타일
const styles = {
  title: {
    color: '#333',
  },
  link: {
    color: '#1890ff',
    textDecoration: 'none',
  },
  linkHover: {
    textDecoration: 'underline',
  }
};

// NewsCard 컴포넌트 정의
const NewsCard = () => {
  const newsData = useNewsData();

  // 마우스 오버 및 리브 이벤트 핸들러 함수
  const handleCellMouseEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleCellMouseLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Card style={newsCardStyle}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '20px' }}>
        <a
          href="https://www.korea.kr/main.do"
          style={styles.link}
          onMouseOver={e => (e.currentTarget.style = styles.linkHover)}
          onMouseOut={e => (e.currentTarget.style = styles.link)}
          target="_blank"
          rel="noopener noreferrer"
        >
          더보기
        </a>
      </div>
      <Table
        dataSource={newsData.slice(0, 10)}
        columns={newsColumns}
        pagination={false}
        rowKey="link"
        // 마우스 오버 및 리브 이벤트 핸들러 추가
        onRow={() => {
          return {
            onMouseEnter: handleCellMouseEnter,
            onMouseLeave: handleCellMouseLeave,
          };
        }}
      />
    </Card>
  );
};

// VideoCard 컴포넌트 스타일
const videoCardStyle = {
  textAlign: 'center',
  padding: '20px',
  width: '100%'
};

// 동영상을 감싸는 div에 대한 스타일
const videoContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '315px'
};

// YouTube API 요청 함수
const fetchYouTubeVideos = async () => {
  const apiKey = 'AIzaSyCpduQGENkcJNiU2G8VhIZkX1MKPA1Fq7Y'; // YouTube API 키
  const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet&chart=mostPopular&regionCode=KR&key=${apiKey}`;
  const response = await fetch(url);
  const data = await response.json();
  return data.items;
};

// VideoCard 컴포넌트 정의
const VideoCard = () => {
  const [video, setVideo] = useState(null);

  useEffect(() => {
    fetchYouTubeVideos().then(videos => {
      if (videos && videos.length > 0) {
        setVideo(videos[0]); // 첫 번째 동영상만 설정
      }
    });
  }, []);

  return (
    <Card style={videoCardStyle}>
      <Title level={2} style={{ color: '#333', marginBottom: '20px' }}>동영상</Title>
      {video && (
        <div style={videoContainerStyle}>
          <iframe
            title={video.snippet.title}
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${video.id}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </Card>
  );
};

// HomeApp 컴포넌트 스타일
const homeAppStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '100px 20px'
};

// HomeApp 컴포넌트 정의
const HomeApp = () => {
  usePageTitle("홈");
  const { isDarkMode } = useDarkMode();
  return (
    <div className={isDarkMode ? 'dark-mode' : ''} style={homeAppStyle}>
      <NewsCard />
      <VideoCard />
    </div>
  );
};

export default HomeApp;