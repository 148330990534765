// src/components/FontContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

export const FontContext = createContext();

export const FontProvider = ({ children }) => {
    const [font, setFont] = useState(() => {
        const savedFont = localStorage.getItem('font');
        return savedFont || "'Noto Sans KR', sans-serif";
    });

    useEffect(() => {
        localStorage.setItem('font', font);
        document.documentElement.style.setProperty('--font-family', font);
    }, [font]);

    return (
        <FontContext.Provider value={{ font, setFont }}>
            {children}
        </FontContext.Provider>
    );
};

export const useFont = () => {
    const context = useContext(FontContext);
    if (!context) {
        throw new Error('useFont must be used within a FontProvider');
    }
    return context;
};